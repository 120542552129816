import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import { ResizeHandler } from './utilities/js/resize-handler';
import Siteheader from './layout/siteheader/siteheader';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.mediaMatchHandler = new MediaMatchHandler();
window.resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;
window.locale = document.querySelector('html').getAttribute('lang');
window.siteheaderAPI = null;
window.anchorTimeout = null;
window.hbsptIds = {};

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    loader: '<span class="loading"><span class="loader"></span></span>'
};

const highlightThemes = [
    ['#4ba8ff', '#000', ''],
    ['#18f59e', '#000', ''],
    ['#9645b5', '#fff', 'inverted'],
    ['#ffc900', '#000', ''],
    ['#ff535d', '#000', '']
];

const onWeglotInitialized = () => {
    const contentUpdateEvent = new CustomEvent('weglot.loaded');
    window.dispatchEvent(contentUpdateEvent);
};

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
};

const setRandomTheme = () => {
    const randomTheme = highlightThemes[Math.floor(Math.random() * highlightThemes.length)];

    const rootStyles = document.querySelector(':root');

    rootStyles.style.setProperty('--random-highlight-theme-background', randomTheme[0]);
    rootStyles.style.setProperty('--random-highlight-theme-foreground', randomTheme[1]);

    const $themeContainers = document.querySelectorAll('[data-theme="root"]');
    $themeContainers.forEach($container => {
        const $buttons = $container.querySelectorAll('[data-theme="button"]');
        $buttons.forEach($button => {
            const buttonTheme = randomTheme[2];
            if (buttonTheme !== '') {
                $button.classList.add('button--' + buttonTheme);
            }
        });

        const $downloads = $container.querySelectorAll('[data-theme="download"]');
        $downloads.forEach($download => {
            const downloadTheme = randomTheme[2];
            if (downloadTheme !== '') {
                $download.classList.add('download--' + downloadTheme);
            }
        });
    });
};

const checkInView = (entries, observer) => {
    entries.forEach((entry) => {
        if (!entry.target.classList.contains('inview')) {
            entry.target.classList.add('inview');
            if (typeof entry.target.dataset.footer === 'undefined') {
                window.inViewObserver.unobserve(entry.target);
            }
        }
    });
};

const jumpToAnchor = (targetId) => {
    let anchorTimeout = window.anchorTimeout;
    const $target = document.getElementById(targetId);

    if ($target !== null) {
        const anchorDimensions = getOffset($target);
        const anchorDimensionsTop = anchorDimensions.top;

        smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);

        clearTimeout(anchorTimeout);
        anchorTimeout = window.setTimeout(() => {
            if (window.siteheaderAPI) {
                window.siteheaderAPI.toggleHeader(false);
            }
        }, 500);
    }
};

window.inViewObserver = new IntersectionObserver(checkInView, {
    threshold: 0.1
});

const initContent = ($content) => {
    console.log('start app');

    const $$anchor = $content.querySelectorAll('a[href*="#"]:not([data-anchor-navigation])');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const currentUrl = window.location.href;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl) {
                jumpToAnchor(targetId);
                e.preventDefault();
            }
        });
    }

    if (window.location.hash && window.siteheaderAPI) {
        const targetId = window.location.hash.split('#')[1];
        jumpToAnchor(targetId);
    }

    setRandomTheme();

    loadLazyImages();
};

domready(function () {
    const initApplication = () => {
        browserCheck();

        window.eventScroller.init();
        window.resizeHandler.init();

        window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');
        setTimeout(() => {
            window.dataInview.forEach((dataInView) => {
                window.inViewObserver.observe(dataInView);
            });
        }, 500);

        const $siteheader = document.querySelector('[data-siteheader="root"]');
        if ($siteheader) {
            const siteheaderAPI = new Siteheader($siteheader);
            siteheaderAPI.initialize();
            window.siteheaderAPI = siteheaderAPI;
        }

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        if (typeof Weglot !== 'undefined') { // eslint-disable-line
            if (Weglot.initialized) { // eslint-disable-line
                onWeglotInitialized();
            } else {
                Weglot.on('initialized', onWeglotInitialized);  // eslint-disable-line
            }
        }

        document.body.classList.add('page--loaded');
        window.addEventListener('beforeunload', () => {
            document.body.classList.remove('page--loaded');
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });

        initContent(document);
    };

    initApplication();
});
